<template>
  <div class="all-rule-list">
    <el-container>
      <div class="tree-con" style="height: 530px">
        <div style="height: 100%" ref="scrollbar">
          <OrgTree
            @showQs="getInfo"
            ref="orgTree"
            @handleAdd="handleAdd()"
            @handleEdit="handleEdit"
            @refreshDataList="getDataList"
            :currentTreeObj="currentTreeObj"
          ></OrgTree>
        </div>
      </div>
      <!-- 	<el-main :style="{ height: clientHeight + 'px' }"> -->
      <div class="qsContent">
        <div
          class="custom-scrollbar"
          style="height: 90%"
          ref="scrollbar"
          v-if="!prescriptionType"
        >
          <div class="phone computer">
            <el-select
              v-model="duoItem"
              placeholder="请选择"
              v-if="submitStatus !== 1 && isDuo"
              @input="setForm"
            >
              <el-option
                v-for="item in duoList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class="form-title">{{ tableName }}</div>
            <v-form-render
              v-if="formJson.widgetList.length"
              :form-json="formJson"
              :form-data="formData"
              :option-data="optionData"
              ref="vFormRef"
            ></v-form-render>
            <div style="text-align: center" v-else>暂无数据</div>
          </div>
          <div class="btn" v-if="formJson.widgetList.length">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>

        <!-- 处方信息添加或者编辑 -->
        <div class="prescription" v-if="prescriptionType" v-loading="loading">
          <div class="drug-type-name">
            <span v-if="prescriptionType == 1">中药处方</span>
            <div
              class="btn-group2"
              v-if="isAddRecipel && prescriptionType == 1"
            >
              <el-button type="primary" @click="handleNewAddRecipel(1)"
                >添加处方</el-button
              >
              <el-button type="" class="drug-btn" @click="showGreetment()"
                >选择协定方</el-button
              >
              <el-button type="" class="drug-btn" @click="showHistory()"
                >历史处方</el-button
              >
            </div>
            <span v-if="prescriptionType == 2">西药/中成药处方</span>
            <!-- <div
              class="btn-group2"
              v-if="isAddRecipel && prescriptionType == 2"
            >
              <el-button type="primary" @click="handleAddRecipel(2)"
                >添加处方</el-button
              >
            </div> -->
          </div>
          <!-- <div class="prescription-name"></div> -->
          <div class="no-data" v-if="isAddRecipel == false && prescriptionType">
            <div class="img-box">
              <img src="@/assets/img/case/precipitation-blank.png" />
            </div>
            <div class="tip-text">暂无处方，请先添加处方</div>
            <div class="btn-group" v-if="prescriptionType == 1">
              <el-button type="primary" @click="handleNewAddRecipel(1)"
                >添加处方</el-button
              >
              <el-button type="" class="drug-btn" @click="showGreetment()"
                >选择协定方</el-button
              >
              <el-button type="" class="drug-btn" @click="showHistory()"
                >历史处方</el-button
              >
            </div>
            <div v-if="prescriptionType == 2">
              <el-button type="primary" @click="handleAddRecipel2(2)"
                >添加处方</el-button
              >
            </div>
          </div>

          <div class="add-con" v-if="isAddRecipel && prescriptionType == 1">
            <div class="exist-list" v-if="realRecipelList.length">
              <div
                class="list-item"
                v-for="(item, index) in realRecipelList"
                :key="index"
              >
                <div class="" v-if="item.editStatus == false">
                  <div class="top-con">
                    <div class="drug-info">
                      <span class="drug-title"
                        >中药处方{{ isCreate ? index : index + 1 }}</span
                      ><span class="drug-desc"
                        >【服药频次：{{
                          item.frequency ? item.frequency : "con-"
                        }}
                        单剂次数：{{
                          item.singleDoses ? item.singleDoses : "-"
                        }}
                        总量：{{ item.total ? item.total : "-" }}付】</span
                      >
                    </div>
                    <div class="btns">
                      <img
                        class="save-btn"
                        src="@/assets/img/case/save.png"
                        @click="showAddGreement(item)"
                        title="存为协定方"
                      />
                      <img
                        src="@/assets/img/case/edit.png"
                        @click="editRealRecipel(item)"
                      />
                      <img
                        src="@/assets/img/case/delete.png"
                        @click="deleteAllDrugInfo(item)"
                      />
                    </div>
                  </div>
                  <div class="drug-name">
                    <div class="name-list">
                      <div
                        class="name-item"
                        v-for="(n, i) in item.mbglPrescriptionDrugsList"
                        :key="i"
                      >
                        <div class="name">{{ n.drugsName }}</div>
                        <div class="unit">
                          {{ n.drugsDose }}{{ n.drugsUseUnit }}
                        </div>
                      </div>
                    </div>
                    <div class="demand">
                      服务要求：{{
                        item.medicationRequirements
                          ? item.medicationRequirements
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
                <!-- 编辑状态 -->
                <div class="edit-status" v-if="item.editStatus == true">
                  <div class="top-con">
                    <div class="drug-info">
                      <span class="drug-title"
                        >中药处方{{
                          isCreate ? realRecipelList.length : index + 1
                        }}</span
                      >
                    </div>
                    <div class="btns">
                      <div @click="updateReciple(item)">
                        <img
                          class="save-btn"
                          src="@/assets/img/case/save.png"
                        />
                        <span class="save-text">保存</span>
                      </div>
                      <div @click="deleteAllDrugInfo(item, index)">
                        <img src="@/assets/img/case/delete.png" />
                        <span class="delete-text">删除</span>
                      </div>
                    </div>
                  </div>
                  <el-table :data="item.addRecipelList" style="padding: 0 22px">
                    <el-table-column label="药品名称">
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.drugInfo && scope.row.drugInfo.id"
                          >{{ scope.row.drugInfo.drugsFullName }}</span
                        >
                        <el-select
                          placeholder="输入药品名称/拼音搜索"
                          v-model="scope.row.drugInfo"
                          style="width: 100%"
                          filterable=""
                          value-key="id"
                          @change="
                            (val) => changeEditDrugInfo(scope.row, val, item)
                          "
                          :filter-method="filterValue"
                          v-else
                        >
                          <el-option
                            v-for="(item, index) in drugList"
                            :key="item.id"
                            :value="item"
                            :label="item.drugsFullName"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="剂量" width="150">
                      <template slot-scope="scope">
                        <el-input
                          v-if="scope.row.drugInfo && scope.row.drugInfo.id"
                          v-model="scope.row.drugsDose"
                        ></el-input>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="单位" width="150">
                      <template slot-scope="scope">
                        <el-select
                          placeholder="请选择"
                          v-model="scope.row.drugsUseUnit"
                          v-if="scope.row.drugInfo && scope.row.drugInfo.id"
                        >
                          <el-option
                            v-for="(item, index) in unitList"
                            :key="index"
                            :value="item.name"
                            :label="item.name"
                          ></el-option>
                        </el-select>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="特殊要求">
                      <template slot-scope="scope">
                        <el-input
                          v-if="scope.row.drugInfo && scope.row.drugInfo.id"
                          v-model="scope.row.medicationRequirements"
                          placeholder="请输入"
                        ></el-input>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                      <template slot-scope="scope">
                        <div
                          class="delete-btn"
                          v-if="scope.row.drugInfo && scope.row.drugInfo.id"
                          @click="deleteDrugInfo(item, scope.row, scope.$index)"
                        >
                          <img src="@/assets/img/case/delete.png" />
                        </div>
                        <div v-else>-</div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-form class="recipel-form" inline style="padding: 0 22px">
                    <div class="recipel-row">
                      <el-form-item label="服药频次">
                        <el-select
                          v-model="item.recipelForm.frequency"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="(n, index) in frequencyList"
                            :key="index"
                            :value="n.name"
                            :label="n.name"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="单剂次数">
                        <el-select
                          v-model="item.recipelForm.singleDoses"
                          style="width: 100%"
                          ><el-option
                            v-for="(n, index) in singleDosesList"
                            :key="index"
                            :value="n.name"
                            :label="n.name"
                          ></el-option
                        ></el-select>
                      </el-form-item>
                      <el-form-item label="总量">
                        <el-input
                          v-model="item.recipelForm.total"
                          style="width: 100%"
                        >
                          <span slot="suffix">付</span>
                        </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="服药要求" style="width: 100%">
                      <el-input
                        type="textarea"
                        v-model="item.recipelForm.medicationRequirements"
                        rows="5"
                        placeholder="请输入"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <!-- 西药/中成药 -->
          <div v-if="isAddRecipel && prescriptionType == 2" style="width: 100%">
            <div
              v-for="(item, index) in addRecipelList2"
              :key="index"
              style="width: 100%"
            >
              <div
                class="add-con"
                style="margin-bottom: 25px"
                v-if="item.drugInfo && item.drugInfo.id"
              >
                <div class="con-title">
                  <div>
                    {{ item.drugsName
                    }}<span style="padding-left: 10px"
                      >{{
                        item.drugInfo.drugsBaseDose &&
                        item.drugInfo.drugsBaseDose != null
                          ? item.drugInfo.drugsBaseDose
                          : ""
                      }}{{
                        item.drugInfo.drugsUseUnitName &&
                        item.drugInfo.drugsUseUnitName != null
                          ? item.drugInfo.drugsUseUnitName
                          : ""
                      }}</span
                    >
                  </div>
                  <div class="close-btn" @click="deleteDrugInfo2(item, index)">
                    <img src="@/assets/img/case/close.png" />
                  </div>
                </div>
                <el-form inline class="west-drug-form" label-width="100px">
                  <el-form-item label="单次剂量">
                    <el-input v-model="item.singleDoses">
                      <span slot="append">
                        <el-select
                          placeholder="请选择"
                          v-model="item.drugsUseUnit"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="(i, index) in unitList"
                            :key="index"
                            :value="i.name"
                            :label="i.name"
                          ></el-option>
                        </el-select>
                      </span>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="用法">
                    <el-select placeholder="请选择" v-model="item.drugsUseType">
                      <el-option
                        v-for="(i, index) in drugsUseTypeList"
                        :key="index"
                        :value="i.name"
                        :label="i.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="用药频次">
                    <el-select placeholder="请选择" v-model="item.frequency">
                      <el-option
                        v-for="(i, index) in frequencyList"
                        :key="index"
                        :value="i.name"
                        :label="i.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="天数">
                    <el-select placeholder="请选择" v-model="item.days">
                      <el-option
                        v-for="(i, index) in 30"
                        :key="index"
                        :value="i"
                        :label="i + '天'"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="总量">
                    <el-input v-model="item.total">
                      <span slot="append">
                        <el-select
                          placeholder="请选择"
                          v-model="item.totalUnit"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="(i, index) in unitList"
                            :key="index"
                            :value="i.name"
                            :label="i.name"
                          ></el-option>
                        </el-select>
                      </span>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="用药说明"
                    class="custom-form-item"
                    style="width: 100%"
                  >
                    <el-input
                      type="textarea"
                      v-model="item.drugsIllustrate"
                      rows="5"
                      placeholder="请输入"
                      style="width: 100%"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div v-else>
                <el-select
                  placeholder="输入药品名称/拼音搜索"
                  v-model="item.drugInfo"
                  style="width: 350px"
                  filterable=""
                  clearable=""
                  value-key="id"
                  @change="(val) => changeDrugInfo2(item, val)"
                >
                  <el-option
                    v-for="(item, index) in drugList"
                    :key="index"
                    :value="item"
                    :label="item.drugsFullName"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="save-btn2"
          @click="addPrescriptionRecipel"
          v-if="isAddRecipel && prescriptionType == 1"
        >
          <el-button type="primary">保存</el-button>
          <img src="@/assets/img/case/save.png" />
        </div> -->
        <div
          class="save-btn"
          @click="addPrescriptionRecipel2"
          v-if="
            isAddRecipel &&
            prescriptionType == 2 &&
            addRecipelList2.length &&
            addRecipelList2[0].drugsId
          "
        >
          <el-button type="primary">保存</el-button>
        </div>
      </div>
      <!-- </el-main> -->
    </el-container>
    <choose-question
      v-if="qsDialog"
      ref="chooseQs"
      @closeeQs="closeeQs()"
      @chooseQs="getOther()"
      :type="5"
    >
    </choose-question>
    <choose-question-update
      v-if="qsDialog2"
      ref="chooseQs2"
      @closeeQs="closeeQs()"
      :ruleFormDetail="ruleFormDetail"
      @chooseQs="getOther()"
      :type="5"
    ></choose-question-update>
    <agreement
      v-if="agreementDialog"
      ref="agreement"
      @refreshData="getSinglePrescriptionInfo(1)"
    ></agreement>
    <add-agreement v-if="addAgreementDialog" ref="addAgreement"></add-agreement>
    <history
      v-if="historyDialog"
      ref="history"
      @addRecipelItem="addRecipelItem"
    ></history>
  </div>
</template>

<script>
import OrgTree from "./tree/tree";
import qs from "qs";
import QRCode from "qrcodejs2";
import Clipboard from "clipboard";
import chooseQuestion from "./components/chooseQuestion";
import chooseQuestionUpdate from "./components/chooseQuestionUpdate";
import agreement from "./components/agreement";
import addAgreement from "./components/addGreement";
import history from "./components/history";
// import {
// 	ref,
// 	reactive
// } from 'vue'

// const vFormRef = ref(null)
export default {
  components: {
    OrgTree,
    chooseQuestion,
    chooseQuestionUpdate,
    agreement,
    addAgreement,
    history,
  },

  data() {
    return {
      isDuo: false,
      duoItem: "",
      relationId: "",
      first: false,
      ruleFormDetail: {},
      activeName: "1",
      id: "",
      tableName: "",
      /* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
      formJson: {
        widgetList: [],
        formConfig: {},
      },
      formData: {},
      optionData: {},
      dialogStatus: false,
      questionName: "", //搜索内容
      isPublish: "", //是否发布
      isUse: "", //是否启用
      formList2: [],
      beforeList: [], //前序活动列表
      formList: [
        {
          executionTime: "",
          activityName: "",
          activityType: "", //活动类型
          activityDescription: "",
          beforeActivityId: "",
          beforeActivityTime: 0, //前序活动距离天数
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          dialog: false, //服药dialog
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
        },
      ],
      circleList: [],
      edit: false, //是否为编辑页面
      allList: [], //所有方案活动
      dataList: [],
      qsDialog: false,
      qsDialog2: false,
      pageIndex: 1,
      pageSize: 10,
      searchContent: "",
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      userId: "",
      unionId: "",
      copyDialog: false,
      url: "",
      contentTypeSec: "",
      contentType: "",
      dataForm: {
        hospitalId: "", //医院id
        teamId: "", //团队id
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
      },
      // rules: {
      //   tableName: [
      //     { required: true, message: "请输入表单名称", trigger: "blur" }
      //   ],
      //   authorityType: [
      //     {
      //       required: true,
      //       message: "请选择表单权限",
      //       trigger: "change"
      //     }
      //   ],
      //   tableModel: [
      //     { required: true, message: "请选择问卷分类", trigger: "change" }
      //   ]
      // },
      array: [],
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
      contentType: "",
      contentTypeSec: "",
      flag: 1,
      submitStatus: "",
      infoId: "",
      taskId: "",
      duoList: [],
      tableRule: 0,
      selectChoose: 1, //新增1编辑2
      tableId: "",
      newArr: [],
      clientHeight: `${document.documentElement.clientHeight}` - 400,
      submitTaskId: "",
      currentTreeObj: "",
      isAddRecipel: false, //是否添加处方
      addRecipelList: [
        {
          drugInfo: {},
          drugsDose: "",
          drugsUseUnit: "",
          medicationRequirements: "",
        },
      ], //要添加的药品信息
      realRecipelList: [], //已保存的列表数目
      drugList: [], //搜索的中药列表
      unitList: [], //用药单位
      frequencyList: [], //频次列表
      singleDosesList: [], //单剂次数
      drugsUseTypeList: [], //用法列表
      dayList: [], //天数
      recipelForm: {
        frequency: "", //频次
        singleDoses: "", //单次剂数
        total: "", //总量
        medicationRequirements: "", //服药要求
        prescriptionType: 1, //中药
      }, //添加药品信息
      prescriptionType: "", //处方类型
      addRecipelList2: [
        {
          drugInfo: {},
          singleDoses: "",
          drugsUseType: "",
          drugsUseUnit: "",
          frequency: "", //用药频次
          days: "", //天数
          total: "", //总量
          totalUnit: "", //总量单位
          drugsIllustrate: "",
          status: 1,
        },
      ],
      loading: false,
      agreementDialog: false,
      isCreate: false, //是否新建一条数据点击添加时候提示保存完才能继续添加
      userInfo: {},
      addAgreementDialog: false, //存为协定方
      historyDialog: false,
    };
  },

  watch: {
    duoItem: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.selectChoose = 2;
        } else {
          this.selectChoose = 1;
        }
      },
    },
  },

  mounted() {
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      this.userId = userInfo.id;
      this.unionId = userInfo.doctorUnionId;
      this.userInfo = userInfo;
    }
    this.getRecipelUnit("单位");
    this.getRecipelUnit("用药频次");
    this.getRecipelUnit("处方用法");
    this.getRecipelUnit("天数");
    this.getRecipelUnit("单剂次数");
    this.getDataList();
    //   this.getActivityList()
  },

  methods: {
    // 提交信息接口
    async submitData(formData, optionData) {
      var that = this;
      let patientInfo = {};
      if (localStorage.patientInfo) {
        patientInfo = JSON.parse(localStorage.patientInfo);
      }
      let params = {};
      let arr = [];
      if (formData != undefined) {
        let typeArr = that.$refs.vFormRef.getFieldWidgets();
        if (typeArr.length) {
          let obj;

          for (let key in formData) {
            obj = {
              infoId: that.infoId,
              teamId: JSON.parse(localStorage.currentTeam).id,
              patientId: patientInfo.patience_id,
              taskId: that.submitTaskId, //that.taskId ? that.taskId : that.infoId,
              relationId: that.relationId,
              cellId: key,
              fillContent: formData[key],
              fillType: "",
            };

            typeArr.forEach((ele) => {
              if (ele.name == key) {
                obj.fillType = ele.type;
              }
            });
            arr.push(obj);
          }
        }

        if (
          (that.submitStatus !== 1 && that.tableRule == 2) ||
          that.tableRule == 3
        ) {
          arr.forEach((item) => {
            this.newArr.forEach((v) => {
              for (let key in v) {
                if (item.cellId === key) {
                  item.id = v[key];
                }
              }
            });
          });
        } else if (
          that.submitStatus !== 1 &&
          that.selectChoose == 2 &&
          that.tableRule == 1
        ) {
          arr.forEach((item) => {
            this.newArr.forEach((v) => {
              for (let key in v) {
                if (item.cellId === key) {
                  item.id = v[key];
                }
              }
            });
          });
        }
        if (arr.length) {
          arr.forEach((chr) => {
            // 判断是否为图片
            if (
              chr.fillType == "picture-upload" ||
              chr.fillType == "file-upload"
            ) {
              let newArray = [];
              if (chr.fillContent && chr.fillContent.length) {
                chr.fillContent.forEach((ele) => {
                  let obj = {};
                  if (ele.response && ele.response.result) {
                    obj = {
                      name: ele.name,
                      url: ele.response.result.filePath,
                    };
                  } else {
                    obj = {
                      name: ele.name,
                      url: ele.url,
                    };
                  }

                  newArray.push(obj);
                });
                chr.fillContent = newArray;
              }
            }
          });
          // console.log("调用了编辑接口",arr);
          // return
          params.stringParam1 = JSON.stringify(arr);
        }

        const { data: res } = await that.$httpAes({
          url: that.$httpAes.adornUrl("/vfWriteInfo/add"),
          method: "post",
          data: params,
        });
        if (res.status) {
          that.updateSubmitStatusById();
        }
      }
    },

    async updateSubmitStatusById() {
      var that = this;
      let params = {
        id: this.taskId,
        submitStatus: 2,
      };
      const { data: res } = await that.$httpAes({
        url: that.$httpAes.adornUrl(`/visitInfo/updateSubmitStatusById`),
        method: "post",
        data: params,
      });
      if (res.status) {
        that.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
          onClose: () => {
            // that.$router.go(0);
            // that.getDataList();
            // that.getInfo(this.currentTreeObj);
            that.$refs["orgTree"].getDeptList(2, this.currentTreeObj.uuid);
          },
        });
      }
    },
    async getInfo(d, n) {
      this.isCreate = false;
      this.realRecipelList = [];
      this.tableName = "";
      this.formJson = {
        widgetList: [],
        formConfig: {},
      };
      this.formData = {};
      this.submitStatus = d.submitStatus;
      this.infoId = d.infoId;
      this.relationId = d.relationId;
      this.taskId = d.id;
      this.currentTreeObj = d;
      this.currentTreeObj.teamsId = JSON.parse(localStorage.currentTeam).id;
      this.currentTreeObj.patientId = JSON.parse(
        localStorage.patientInfo
      ).patience_id;
      this.currentTreeObj.hospitalId = JSON.parse(
        localStorage.userInfo
      ).deptPid;
      if (d.currentId) {
        this.currentTreeObj.visitUuid = d.currentId;
      }
      if (this.infoId) {
        this.prescriptionType = false;
      } else {
        if (d.indexNum >= 0) {
          this.prescriptionType = false;
        } else {
          this.prescriptionType = d.prescriptionType;
        }
      }

      console.log("123", n);
      // this.prescriptionType = d.prescriptionType;
      if (this.prescriptionType) {
        // if (d.mbglPrescriptionDrugsList && d.mbglPrescriptionDrugsList.length) {
        //   this.isAddRecipel = true;
        // } else {
        //   this.isAddRecipel = false;
        // }
        if (d.name == "中药处方") {
          this.handleAddRecipel(1);
        } else if (d.name == "西药/中成药处方") {
          this.handleAddRecipel(2);
        }
      } else {
        this.isAddRecipel = false;
        this.addRecipelList = [{}];
        this.addRecipelList2 = [{}];
      }

      console.log("=======", d);
      var that = this;
      if (d && d.infoId) {
        const { data } = await this.$httpAes({
          url: that.$httpAes.adornUrl("/vfTableInfo/info"),
          method: "post",
          data: {
            id: d.infoId,
            stringParam1: d.infoId,
          },
        });
        if (data.status && data.data) {
          this.duoItem = "";
          this.tableName = data.data.tableName;
          this.tableId = data.data.id;
          if (data.data && data.data.tableContent) {
            this.formJson = JSON.parse(data.data.tableContent);
            console.log("123", this.formJson);
            // return;
            // this.$nextTick(() => {
            //   this.$refs.vFormRef.setFormData(this.formJson)
            // })
            this.tableRule = data.data.tableRule;
            if (data.data.tableRule == 1) {
              //多次填写
              this.getDuoWrite(d);
              this.isDuo = true;
            } else {
              this.isDuo = false;
              this.getWriteInfo(d);
              if (this.submitStatus != 1) {
                //一次填写 已填
                // this.$nextTick(() => {
                // 	this.$refs["vFormRef"].disableForm(true);
                // });
              }
            }

            // if (this.$route.query.activeName == 3) {
            // 	this.getWriteInfo();
            // }

            // console.log(this.formJson);
          }
        } else {
          that.tableName = "";
          that.$message({
            message: data.data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      }
    },
    async handleDelete(s, d, n) {
      this.$confirm("确定进行[删除]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/visitInfo/delete?uuid=${d.uuid}`),
            method: "post",
            data: {
              stringParam1: d.uuid,
            },
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    async getWriteInfo(d) {
      var that = this;
      let formInfo = {};
      if (localStorage.currentPatientFormInfo) {
        formInfo = JSON.parse(localStorage.currentPatientFormInfo);
      }
      let taskId = "";
      if (d.taskId && d.taskId != "") {
        taskId = d.taskId;
      } else {
        taskId = d.id;
      }
      that.submitTaskId = taskId;
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfWriteInfo/info"),
        method: "post",
        data: {
          stringParam1: d.infoId,
          // relationId: formInfo.id,
          stringParam4: taskId,
          stringParam2: d.teamsId,
          stringParam5: JSON.parse(localStorage.patientInfo).patience_id,
        },
      });
      if (data.status) {
        this.newArr = [];
        let list = [],
          newArr = [];
        if (data.data) {
          list = JSON.parse(data.data);
          console.log("listlistlistlistlist", list);
          if (list.length) {
            list.forEach((ele) => {
              if (
                ele.cellId.indexOf("subform") > -1 &&
                ele.fillContent &&
                ele.fillContent.indexOf("[") > -1
              ) {
                ele.fillContent = JSON.parse(ele.fillContent);
              }
              if (
                ele.fillType == "date-range" ||
                ele.fillType == "time-range"
              ) {
                if (ele.fillContent) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                }
              }
              if (ele.fillType == "checkbox") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                }
                // if (typeof ele.fillContent == 'number') {
                //   ele.fillContent = [ele.fillContent]
                // }
              }
              if (ele.fillType == "switch") {
              }
              // ele.fillType == "number" ||
              if (ele.fillType == "radio" || ele.fillType == "slider") {
                console.log(typeof ele.fillContent);
                ele.fillContent = parseInt(ele.fillContent);
                // if (typeof ele.fillContent == "string") {

                // }
              }
              if (
                (ele.fillType == "picture-upload" ||
                  ele.fillType == "file-upload" ||
                  ele.fillType == "pictureupload") &&
                ele.fillContent
              ) {
                if (typeof ele.fillContent == "string") {
                  if (ele.fillContent.indexOf("[") > -1) {
                    ele.fillContent = JSON.parse(ele.fillContent);
                  } else {
                    ele.fillContent = [
                      {
                        url: ele.fillContent,
                        name: "图片",
                      },
                    ];
                  }
                }
              }
              if (ele.fillType == "select") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                } else {
                  ele.fillContent = parseInt(ele.fillContent);
                }
              }
              let newObj = {
                [ele.cellId]: ele.fillContent,
              };
              const newObj1 = {
                [ele.cellId]: ele.id,
              };
              newArr.push(newObj);
              this.newArr.push(newObj1);
            });
          }
          let formData = {};
          for (let key in [...newArr]) {
            formData = Object.assign(this.formData, [...newArr][key]);
          }
          console.log("formData", formData);
          this.$nextTick(() => {
            this.$refs["vFormRef"].setFormData(formData);
          });
        }
      } else {
        that.$message({
          message: data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    async getDuoWrite(d) {
      this.newArr = [];
      var that = this;
      let formInfo = {};
      if (localStorage.currentPatientFormInfo) {
        formInfo = JSON.parse(localStorage.currentPatientFormInfo);
      }
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfWriteInfo/dzxInfo"),
        method: "post",
        params: {
          stringParam1: d.infoId,
          // relationId: formInfo.id,
          stringParam4: d.id,
          stringParam2: d.teamsId,
          stringParam5: JSON.parse(localStorage.patientInfo).patience_id,
        },
      });
      if (data.status) {
        console.log(data, "duo data");
        this.duoList = data.data.writeNumbers;
      } else {
        that.$message({
          message: data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    async setForm() {
      this.newArr = [];
      if (this.duoItem) {
        this.selectChoose = 2;
      } else {
        this.selectChoose = 1;
      }
      console.log(this.selectChoose, "this.selectChoose");
      console.log("set");
      if (this.duoItem) {
        const { data: res } = await this.$http({
          url: this.$http.adornUrl("//dzxInfo"),
          method: "get",
          params: {
            writeNumber: this.duoItem,
            // infoId: d.infoId,
            // taskId: d.id,
            // teamId: d.teamsId,
            patientId: JSON.parse(localStorage.patientInfo).patience_id,
          },
        });
        console.log(res);
        let list = [],
          newArr = [];
        list = JSON.parse(res.data.list);
        console.log("list", list);
        if (list.length) {
          list.forEach((ele) => {
            if (ele.fillType == "checkbox") {
              if (ele.fillContent.indexOf("[") > -1) {
                ele.fillContent = JSON.parse(ele.fillContent);
              }
              // if (typeof ele.fillContent == 'number') {
              //   ele.fillContent = [ele.fillContent]
              // }
            }
            if (
              ele.fillType == "number" ||
              ele.fillType == "radio" ||
              ele.fillType == "slider"
            ) {
              console.log(typeof ele.fillContent);
              ele.fillContent = parseInt(ele.fillContent);
              // if (typeof ele.fillContent == "string") {

              // }
            }
            if (
              ele.fillType == "picture-upload" ||
              ele.fillType == "file-upload" ||
              ele.fillType == "pictureupload"
            ) {
              if (typeof ele.fillContent == "string") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                } else {
                  ele.fillContent = [
                    {
                      url: ele.fillContent,
                      name: "图片",
                    },
                  ];
                }
              }
            }
            if (ele.fillType == "select") {
              if (ele.fillContent.indexOf("[") > -1) {
                ele.fillContent = JSON.parse(ele.fillContent);
              } else {
                ele.fillContent = parseInt(ele.fillContent);
              }
            }
            let newObj = {
              [ele.cellId]: ele.fillContent,
            };
            const newObj1 = {
              [ele.cellId]: ele.id,
            };
            newArr.push(newObj);
            this.newArr.push(newObj1);
          });
        }
        console.log("list", list);
        let formData = {};
        for (let key in [...newArr]) {
          formData = Object.assign(this.formData, [...newArr][key]);
        }
        this.$nextTick(() => {
          this.$refs["vFormRef"].setFormData(formData);
          // this.$refs["vFormRef"].disableForm(true);
        });
      } else {
        // this.$refs["vFormRef"].disableForm(false);
      }
    },
    submitForm() {
      this.$refs.vFormRef
        .getFormData()
        .then((formData) => {
          this.submitData(formData);
        })
        .catch((error) => {
          // Form Validation failed
          this.$message.error(error);
        });
    },
    closeeQs() {
      this.qsDialog = false;
      this.qsDialog2 = false;
      this.$nextTick(() => {
        this.$refs["orgTree"].getDeptList(1);
      });
    },
    async getActivityList() {
      const { data: result } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/programActivity/list"),
        method: "post",
        data: {
          stringParam2: this.programInfoId,
          intParam1: 999,
        },
      });
      if (result.status) {
        result.data.forEach((v) => {
          v.leftShow = true;
          v.show = false;
          v.dialog = false;
          v.qsDialog = false;
          // 如果有主题内容--药物信息
          if (v.drugsManageId || v.missionaryWorkId || v.followUpId) {
            v.flag = true;
            if (v.drugsManages) {
              v.medicineList = v.drugsManages;
            }
          } else {
            v.flag = false;
            v.medicineList = [];
          }
          // 如果有主题内容--宣教信息--之前用了其他字段名的列表来装 后面就跟拿到的列表名保持一致好了
          // if(){
          //   v.flag = true
          // }else{
          //   v.flag = false
          // }
        });
        result.data.sort((a, b) => {
          return b.orderNum - a.orderNum;
        });
        let once = result.data.filter((v) => {
          return v.activityMode == 1;
        });
        let circle = result.data.filter((v) => {
          return v.activityMode == 2;
        });
        this.allList = result.data;
        this.circleList = circle;
        this.formList = once;
        this.formList2 = JSON.parse(JSON.stringify(once));
        this.dataListLoading = false;
      }
    },
    getOther(val, index) {
      this.formList[index].flag = true;
      console.log(val, "val gteOther", index);
      if (this.formList[index].activityType === 4) {
        // this.formList[index].missionaryWorks = [{workTypeName:val.workTypeName,workName:val.workName,item:val}]
        this.formList[index].missionaryWorks = [val];
      } else {
        // this.formList[index].indexLibraries = [{libraryTypeName:val.type.name,libraryName:val.item.libraryName,item:val.item}]
        this.formList[index].indexLibraries = [val];
      }
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        stringParam1: teamsId,
        // doctorId: doctorId,
        stringParam2: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/visitInfo/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.dataList = data.data;
            that.oldList = data.data;
            that.dataListLoading = false;
            if (that.dataList.length == 0) {
              that.$refs.chooseQs.first = true;
            } else {
              // that.$refs.chooseQs.first = false;
            }
          }
        });
    },
    async handleAdd() {
      this.qsDialog = true;
    },
    handleEdit(s, d, n) {
      this.ruleFormDetail = s;
      this.qsDialog2 = true;
      this.$nextTick(() => {
        this.$refs.chooseQs2.init(d.data);
      });
      // console.log(s);
      console.log(d);
      // console.log(n);
    },
    handleChange(node) {
      console.log(node);
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
        this.array = node;
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$http({
        url: _this.$http.adornUrl(
          "/dict/getVfTableSysDicts?type=" + "表单类别"
        ),
        method: "get",
        params: {
          teamsId,
          pageSize: 999,
        },
      });

      if (data.status) {
        _this.menuList = data.data;
        this.changeData(_this.menuList);
        if (this.dataForm.tableModel) {
          this.array[0] = parseInt(this.dataForm.tableModel);
        }
        if (this.dataForm.tableType) {
          this.array[1] = this.dataForm.tableType;
        }
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    resetValue() {
      this.questionName = "";
      this.isPublish = "";
      this.isUse = "";
    },
    // getDataList(d, n) {
    //   let teamsId = "";
    //   let enertyId = "";
    //   if (localStorage.currentTeam) {
    //     teamsId = JSON.parse(localStorage.currentTeam).id;
    //     enertyId = JSON.parse(localStorage.currentTeam).entityId;
    //   }
    //   let userInfo = {};
    //   if (localStorage.userInfo) {
    //     userInfo = JSON.parse(localStorage.userInfo);
    //   }
    //   let type = "";
    //   let typeSec = "";
    //   let tableBelongs = "";
    //   if (d) {
    //     if (d.isEdit == false) {
    //       tableBelongs = 1;
    //     }
    //     if (d.type == "表单类别") {
    //       type = d.value;
    //       typeSec = "";
    //       this.contentType = d.value;
    //       this.contentTypeSec = "";
    //     } else {
    //       type = n.parent.data.value;
    //       typeSec = d.value;
    //       this.contentType = n.parent.data.value;
    //       this.contentTypeSec = d.value;
    //     }
    //   }
    //   this.dataListLoading = true;
    //   this.$http({
    //     url: this.$http.adornUrl("/vfTableInfo/list"),
    //     method: "get",
    //     params: {
    //       hospitalId: userInfo.deptPid,
    //       pageSize: this.pageSize,
    //       pageNo: this.pageIndex,
    //       tableName: this.questionName,
    //       publishStatus: this.isPublish,
    //       isUse: this.isUse,
    //       tableBelongs: 1,
    //       tableType: typeSec,
    //       tableModel: type,
    //       teamId: teamsId
    //     }
    //   }).then(({ data }) => {
    //     if (data.status) {
    //       this.dataList = data.data;
    //       this.totalPage = data.totalCount;
    //     } else {
    //       this.dataList = [];
    //       this.totalPage = 0;
    //     }
    //     this.dataListLoading = false;
    //   });
    // },
    // handelChange(val) {
    //   this.getDataList();
    // },
    // // 每页数
    // sizeChangeHandle(val) {
    //   this.pageSize = val;
    //   this.pageIndex = 1;
    //   this.getDataList();
    // },
    // // 当前页
    // currentChangeHandle(val) {
    //   this.pageIndex = val;
    //   this.getDataList();
    // },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //禁用
    // disableClick(status) {
    //   var that = this;
    //   let ids = that.dataListSelections.map(item => {
    //     return item.id;
    //   });
    //   let statusName = "";
    //   if (status == 1) {
    //     statusName = "发布成功";
    //   } else if (status == 2) {
    //     statusName = "启用成功";
    //   } else if (status == 3) {
    //     statusName = "禁用成功";
    //   } else if (status == 4) {
    //     statusName = "删除成功";
    //   }
    //   if (status == 4) {
    //     this.$confirm(`确定进行删除操作?`, "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning"
    //     })
    //       .then(() => {
    //         that
    //           .$http({
    //             url: that.$http.adornUrl("/vfTableInfo/operate"),
    //             method: "post",
    //             data: qs.stringify({
    //               ids: ids.join(","),
    //               type: status
    //             })
    //           })
    //           .then(({ data }) => {
    //             if (data.status) {
    //               that.$message({
    //                 message: statusName,
    //                 type: "success",
    //                 duration: 1500,
    //                 onClose: () => {
    //                   that.getDataList();
    //                 }
    //               });
    //             } else {
    //               that.$message({
    //                 message: data.msg,
    //                 type: "error",
    //                 duration: 1500,
    //                 onClose: () => {}
    //               });
    //             }
    //           });
    //       })
    //       .catch(() => {});
    //   } else {
    //     that
    //       .$http({
    //         url: that.$http.adornUrl("/vfTableInfo/operate"),
    //         method: "post",
    //         data: qs.stringify({
    //           ids: ids.join(","),
    //           type: status
    //         })
    //       })
    //       .then(({ data }) => {
    //         if (data.status) {
    //           that.$message({
    //             message: statusName,
    //             type: "success",
    //             duration: 1500,
    //             onClose: () => {
    //               that.getDataList();
    //             }
    //           });
    //         } else {
    //           that.$message({
    //             message: data.msg,
    //             type: "error",
    //             duration: 1500,
    //             onClose: () => {}
    //           });
    //         }
    //       });
    //   }
    // },

    addSubject() {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          contentType: this.contentType,
          contentTypeSec: this.contentTypeSec,
        },
      });
    },
    copyAdd(row) {
      this.copyDialog = true;
      this.getDictList();
      this.dataForm = {
        hospitalId: row.hospitalId, //医院id
        teamId: row.teamId, //团队id
        tableName: row.tableName, //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: row.orderWeight, //权重
        tableType: row.tableType,
        tableModel: row.tableModel,
        authorityType: row.authorityType,
        tableContent: row.tableContent,
        publishStatus: row.publishStatus,
        isUse: row.isUse,
        tableRule: row.tableRule,
        scoreSetting: row.scoreSetting,
      };
    },
    //提交指标库
    submitSubject() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      if (localStorage.userInfo) {
        this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }

      if (_this.unionId) {
        _this.dataForm.unionId = _this.unionId;
      }
      let params = JSON.parse(JSON.stringify(_this.dataForm));
      params.orderWeight = 0;
      // console.log(params);
      // return
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl("/vfTableInfo/add"),
                method: "post",
                data: params,
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.flag = 1;
                      _this.copyDialog = false;
                      _this.getDataList();
                    },
                  });
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
    handleViewEdit(index, row) {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          id: row.id,
          type: 2,
        },
      });
    },
    handleViewInfo(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
        },
      });
    },
    // 预览
    handlePreview(row) {
      this.$router.push({
        path: "/dataCollect/preview",
        query: {
          id: row.id,
        },
      });
    },
    // 数据
    handleData(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
          activeName: 3,
        },
      });
    },
    // 添加处方
    handleAddRecipel(num) {
      if (num == 1) {
        this.isAddRecipel = true;
      }
      this.getDrugList(num);
    },
    // 获取单个处方记录的药品信息
    getSinglePrescriptionInfo(num) {
      let params = {
        stringParam2: this.currentTreeObj.teamsId,
        stringParam4: this.currentTreeObj.patientId,
        intParam1: 10000,
        intParam2: 1,
        intParam4: num,
        stringParam3: this.currentTreeObj.visitUuid,
        // ids: this.currentTreeObj.id,
      };
      // this.loading = true;
      // this.getDrugList(2);
      this.$httpAes({
        url: this.$httpAes.adornUrl("/mbglPrescriptionRecords/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          let res = "";
          if (data.data && data.data.length) {
            this.isAddRecipel = true;
            res = data.data[0];
            if (res.prescriptionType == 2) {
              this.currentTreeObj = res;
            }
            this.realRecipelList = data.data;
            if (this.realRecipelList.length) {
              this.realRecipelList.forEach((ele) => {
                ele.editStatus = false;
              });
            }
          } else {
            this.isAddRecipel = false;
          }

          if (
            res.mbglPrescriptionDrugsList &&
            res.mbglPrescriptionDrugsList.length
          ) {
            if (res.prescriptionType == 2) {
              this.addRecipelList2 = [];
              res.mbglPrescriptionDrugsList.forEach((ele) => {
                let obj = {
                  drugInfo: {
                    id: ele.drugsId,
                    drugsFullName: ele.drugsName,
                  },
                  id: ele.id,
                  drugsId: ele.drugsId,
                  drugsName: ele.drugsName,
                  drugsUseUnit: ele.drugsUseUnit,
                  singleDoses: ele.singleDoses,
                  frequency: ele.frequency,
                  specifications: ele.specifications,
                  drugsUseType: ele.drugsUseType,
                  days: ele.days,
                  total: ele.total,
                  totalUnit: ele.totalUnit,
                  drugsIllustrate: ele.drugsIllustrate,
                  status: 1,
                  prescriptionRecordsId: data.data[0].id,
                };
                if (this.drugList.length) {
                  this.drugList.forEach((chr) => {
                    if (chr.id == ele.drugsId) {
                      obj.drugInfo = chr;
                    }
                  });
                }

                this.addRecipelList2.push(obj);
              });
              this.addRecipelList2.push({
                drugInfo: {},
                singleDoses: "",
                drugsUseType: "",
                frequency: "",
                days: "",
                total: "",
                totalUnit: "",
                drugsIllustrate: "",
                status: 1,
              });
              console.log("addRecipelList2", this.addRecipelList2);
            }
          } else {
            this.recipelForm = {
              frequency: "", //频次
              singleDoses: "", //单次剂数
              total: "", //总量
              medicationRequirements: "", //服药要求
              prescriptionType: 1, //中药
            };
            // this.isAddRecipel = false;
            this.addRecipelList = [{}];
            this.addRecipelList2 = [{}];
          }
          this.loading = false;
        } else {
          this.recipelForm = {
            frequency: "", //频次
            singleDoses: "", //单次剂数
            total: "", //总量
            medicationRequirements: "", //服药要求
            prescriptionType: 1, //中药
          };
          // this.isAddRecipel = false;
          this.addRecipelList = [{}];
          this.addRecipelList2 = [{}];
        }
      });
    },
    handleAddRecipel2(num) {
      this.isAddRecipel = true;
    },
    // 获取药品列表
    getDrugList(type) {
      let currentTeam = {};
      if (localStorage.currentTeam) {
        currentTeam = JSON.parse(localStorage.currentTeam);
      }
      let params = {
        stringParam1: "",
        intParam1: 10000,
        intParam2: 1,
        intParam3: type,
      };
      this.loading = true;
      // if (type == 2) {
      //   params.hospitalId = currentTeam.hospitalId;
      //   params.deptId = currentTeam.hospitalId;
      // }
      this.$httpAes({
        url: this.$httpAes.adornUrl("/drugsManage/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.drugList = data.data;
          // this.getSinglePrescriptionInfo();
          this.getSinglePrescriptionInfo(type);
          this.loading = false;
        }
      });
    },
    //根据拼音搜索列表
    filterValue(value) {
      console.log(value);
      let params = {
        stringParam1: value,

        intParam1: 10000,
        intParam2: 1,
        intParam3: 1,
      };
      // if (type == 2) {
      //   params.hospitalId = currentTeam.hospitalId;
      //   params.deptId = currentTeam.hospitalId;
      // }
      this.$httpAes({
        url: this.$httpAes.adornUrl("/drugsManage/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.drugList = data.data;
          // this.getSinglePrescriptionInfo();
        }
      });
    },
    // 获取用药单位
    getRecipelUnit(typeName) {
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=" + typeName),
        method: "post",
        data: {
          stringParam1: typeName,
        },
      }).then(({ data }) => {
        if (typeName == "单位") {
          this.unitList = data.data;
        }
        if (typeName == "单剂次数") {
          this.singleDosesList = data.data;
        }
        if (typeName == "用药频次") {
          this.frequencyList = data.data;
        }
        if (typeName == "处方用法") {
          this.drugsUseTypeList = data.data;
        }
        if (typeName == "天数") {
          this.dayList = data.data;
        }
      });
    },
    // 改变药品名称
    changeDrugInfo(row, value) {
      let num = 0;
      row = JSON.parse(JSON.stringify(row));
      if (row.drugInfo && row.drugInfo.id) {
        row.drugsDose = value.drugsBaseDose;
        row.drugsUseUnit = value.drugsUseUnitName ? value.drugsUseUnitName : "";
      }
      this.addRecipelList.forEach((ele) => {
        if (ele.drugInfo && ele.drugInfo.id) {
          num++;
        }
      });
      if (num == this.addRecipelList.length) {
        this.addRecipelList.push({
          drugInfo: {},
          drugsId: "",
          drugsName: "",
          drugsDose: "",
          drugsUseUnit: "",
          medicationRequirements: "",
        });
      }
    },
    // 存在处方记录添加处方
    handleNewAddRecipel() {
      if (this.isCreate) {
        this.$message.error("请先保存当前处方！");
        return;
      }
      let obj = {
        editStatus: true,
        addRecipelList: [
          {
            drugInfo: {},
            drugsDose: "",
            drugsUseUnit: "",
            medicationRequirements: "",
          },
        ],
        recipelForm: {
          frequency: "", //频次
          singleDoses: "", //单次剂数
          total: "", //总量
          medicationRequirements: "", //服药要求
          prescriptionType: 1, //中药
        }, //添加药品信息
      };
      this.realRecipelList.unshift(obj);
      this.isCreate = true;
      this.isAddRecipel = true;
      // this.getDrugList(this.prescriptionType);
      // this.getSinglePrescriptionInfo(1);
      // this.getRecipelUnit("单位");
      // this.getRecipelUnit("用药频次");
      // this.getRecipelUnit("处方用法");
      // this.getRecipelUnit("天数");
      // this.getRecipelUnit("单剂次数");
    },
    // 新增处方中的药品信息
    addPrescriptionRecipel() {
      let arr = [],
        isNull = "";
      if (this.addRecipelList.length > 1) {
        this.addRecipelList.forEach((ele) => {
          if (ele.drugInfo && ele.drugInfo.id) {
            if (!(ele.drugsDose && ele.drugsUseUnit)) {
              isNull++;
              return;
            }
            let obj = {
              drugsId: ele.drugInfo.id,
              drugsName: ele.drugInfo.drugsFullName,
              drugsDose: ele.drugsDose,
              drugsUseUnit: ele.drugsUseUnit,
              medicationRequirements: ele.medicationRequirements,
              status: 1,
              prescriptionRecordsId: this.currentTreeObj.id,
            };
            if (ele.id) {
              obj.id = ele.id;
            }
            arr.push(obj);
          }
        });
      } else {
        isNull++;
      }
      if (isNull) {
        this.$message.error("请完善药品信息");
        return;
      }
      let params = {
        // mbglPrescriptionDrugsList: JSON.stringify(arr),
        id: this.currentTreeObj.id,
        teamsId: this.currentTreeObj.teamsId,
        patientId: this.currentTreeObj.patientId,
        visitUuid: this.currentTreeObj.visitUuid,
        status: 1,
      };
      params = Object.assign({}, params, this.recipelForm);
      console.log(arr);
      // return
      this.$httpAes({
        url: this.$httpAes.adornUrl("/mbglPrescriptionRecords/add"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status && data.data.id) {
          this.addDrugInfo(arr, data.data.id);
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
    },
    // 添加药品信息
    addDrugInfo(arr, id, num) {
      if (arr.length) {
        arr.forEach((ele) => {
          ele.prescriptionRecordsId = id;
        });
      }
      let params = {
        list: JSON.stringify(arr),
      };
      this.$http({
        url: this.$http.adornUrl("/mbglPrescriptionDrugs/adds"),
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: arr,
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: data.msg,
            type: "success",
            duration: 1500,
            onClose: () => {
              // this.$refs["orgTree"].getDeptList();
              this.isAddRecipel = false;
              // this.getInfo(this.currentTreeObj);
              this.getSinglePrescriptionInfo(num);
            },
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
    },
    // 编辑状态改变药品名称
    changeEditDrugInfo(row, value, item) {
      let num = 0;
      row = JSON.parse(JSON.stringify(row));
      if (row.drugInfo && row.drugInfo.id) {
        row.drugsDose = value.drugsBaseDose;
        row.drugsUseUnit = value.drugsUseUnitName ? value.drugsUseUnitName : "";
      }
      item.addRecipelList.forEach((ele) => {
        if (ele.drugInfo && ele.drugInfo.id) {
          num++;
        }
      });
      if (num == item.addRecipelList.length) {
        item.addRecipelList.push({
          drugInfo: {},
          drugsId: "",
          drugsName: "",
          drugsDose: "",
          drugsUseUnit: "",
          medicationRequirements: "",
        });
      }
      this.$forceUpdate();
    },
    // 点击编辑按钮显示编辑状态的页面
    editRealRecipel(item) {
      console.log(item);
      item.editStatus = true;
      item.addRecipelList = [];
      let res = item;
      res.mbglPrescriptionDrugsList.forEach((ele) => {
        let obj = {
          drugInfo: {
            id: ele.drugsId,
            drugsFullName: ele.drugsName,
          },
          id: ele.id,
          drugsDose: ele.drugsDose,
          drugsId: ele.drugsId,
          drugsName: ele.drugsName,
          drugsUseUnit: ele.drugsUseUnit,
          prescriptionRecordsId: ele.prescriptionRecordsId,
          medicationRequirements: ele.medicationRequirements,
        };
        item.addRecipelList.push(obj);
      });
      item.addRecipelList.push({
        drugInfo: {},
        drugsDose: "",
        drugsUseUnit: "",
        medicationRequirements: "",
      });
      let recipelForm = {
        teamsId: res.teamsId,
        patientId: res.patientId,
        visitUuid: res.visitUuid,
        frequency: res.frequency,
        singleDoses: res.singleDoses,
        total: res.total,
        medicationRequirements: res.medicationRequirements,
      };
      this.$set(item, "recipelForm", recipelForm);
      this.$forceUpdate();
    },
    // 编辑状态下保存药品更新信息
    updateReciple(item) {
      let arr = [],
        isNull = "";
      if (item.addRecipelList.length) {
        item.addRecipelList.forEach((ele) => {
          if (ele.drugInfo && ele.drugInfo.id) {
            if (!(ele.drugsDose && ele.drugsUseUnit)) {
              isNull++;
              return;
            }
            let obj = {
              drugsId: ele.drugInfo.id,
              drugsName: ele.drugInfo.drugsFullName,
              drugsDose: ele.drugsDose,
              drugsUseUnit: ele.drugsUseUnit,
              medicationRequirements: ele.medicationRequirements,
              status: 1,
              prescriptionRecordsId: this.currentTreeObj.id,
            };
            if (ele.id) {
              obj.id = ele.id;
            }
            arr.push(obj);
          }
        });
      } else {
        isNull++;
      }
      // console.log(item.addRecipelList);
      if (isNull) {
        this.$message.error("请完善药品信息");
        return;
      }
      let params = {
        // mbglPrescriptionDrugsList: JSON.stringify(arr),
        // id: this.currentTreeObj.id,
        teamsId: this.currentTreeObj.teamsId,
        patientId: this.currentTreeObj.patientId,
        visitUuid: this.currentTreeObj.visitUuid,
        status: 1,
      };
      params = Object.assign({}, params, item.recipelForm);
      if (item.id && item.id != "1" && item.id != "2") {
        params.id = item.id;
      }
      console.log(arr);
      // return
      this.$httpAes({
        url: this.$httpAes.adornUrl(
          params.id
            ? "/mbglPrescriptionRecords/update"
            : "/mbglPrescriptionRecords/add"
        ),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status && data.data.id) {
          this.addDrugInfo(arr, data.data.id);
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
    },
    // 删除整个中药信息
    deleteAllDrugInfo(item, index) {
      console.log(item);

      if (item && item.id) {
        let id = "";
        if (this.prescriptionType == 1) {
          id = item.id;
        }
        if (this.prescriptionType == 2) {
          id = item.prescriptionRecordsId;
        }
        this.$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonClass: "btn-custom-cancel",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$httpAes({
            url: this.$httpAes.adornUrl("/mbglPrescriptionRecords/deleteByIds"),
            method: "post",
            // headers: {
            //   "Content-Type": "application/json",
            // },
            data: { stringParam1: id },
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  // this.$refs["orgTree"].getDeptList();
                  this.getInfo(this.currentTreeObj);
                  // this.isAddRecipel = false;
                },
              });
            } else {
              this.$message({
                message: data.msg,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          });
        });
      } else {
        if (this.prescriptionType == 1) {
          this.realRecipelList.splice(index, 1);
          this.isCreate = false;
        }
        if (!this.realRecipelList.length) {
          this.isAddRecipel = false;
        }
        if (this.prescriptionType == 2) {
          this.addRecipelList2.splice(index, 1);
        }
      }
    },
    // 删除中药填写的药品信息
    deleteDrugInfo(item, row, index) {
      if (row.id) {
        this.$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonClass: "btn-custom-cancel",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          row.status = 2;
          let arr = [];
          arr.push(row);
          this.$http({
            url: this.$http.adornUrl("/mbglPrescriptionDrugs/adds"),
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            data: arr,
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  // this.$refs["orgTree"].getDeptList();
                  // this.getInfo(this.currentTreeObj);
                  // this.getSinglePrescriptionInfo(1);
                  item.addRecipelList.splice(index, 1);
                },
              });
            } else {
              this.$message({
                message: data.msg,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          });
        });
      } else {
        item.addRecipelList.splice(index, 1);
      }
    },
    // 西药
    // 改变药品名称
    changeDrugInfo2(row, value) {
      if (value && value.id) {
        row.drugsName = value.drugsFullName;
        row.drugsId = value.id;
        row.drugsDose = value.drugsBaseDose;
        // row.drugsUseUnit = value.drugsUseUnitName ? value.drugsUseUnitName : "";
        row.drugsUseUnitName = value.drugsUseUnitName;
        // row.totalUnit = value.drugsUseUnitName;
      }
      //
      this.addRecipelList2.push({
        drugInfo: {},
        singleDoses: "",
        drugsUseType: "",
        drugsUseUnit: "",
        frequency: "", //用药频次
        days: "", //天数
        total: "", //总量
        totalUnit: "", //总量单位
        drugsIllustrate: "",
        status: 1,
      });
      this.addRecipelList2 = JSON.parse(JSON.stringify(this.addRecipelList2));
    },
    // 新增处方西药药品信息
    addPrescriptionRecipel2() {
      console.log("currentTreeObj", this.currentTreeObj);
      let arr = [];
      if (this.addRecipelList2.length) {
        this.addRecipelList2.forEach((ele) => {
          if (ele.drugInfo && ele.drugInfo.id) {
            let obj = {
              drugsId: ele.drugInfo.id,
              drugsName: ele.drugInfo.drugsFullName,
              drugsUseUnit: ele.drugsUseUnit ? ele.drugsUseUnit : "",
              specifications:
                (ele.drugInfo.drugsBaseDose ? ele.drugInfo.drugsBaseDose : "") +
                (ele.drugInfo.drugsUseUnitName
                  ? ele.drugInfo.drugsUseUnitName
                  : ""),
              singleDoses: ele.singleDoses ? ele.singleDoses : "",
              frequency: ele.frequency ? ele.frequency : "",
              drugsUseType: ele.drugsUseType ? ele.drugsUseType : "",
              days: ele.days ? ele.days : "",
              total: ele.total ? ele.total : "",
              totalUnit: ele.totalUnit ? ele.totalUnit : "",
              drugsIllustrate: ele.drugsIllustrate ? ele.drugsIllustrate : "",
              status: 1,
            };
            if (ele.id) {
              obj.id = ele.id;
            }
            if (this.currentTreeObj.id && this.currentTreeObj.id != "21") {
              obj.prescriptionRecordsId = this.currentTreeObj.id;
            }
            arr.push(obj);
          }
        });
      }
      let params = {
        // mbglPrescriptionDrugsList: arr,
        // id: this.currentTreeObj.id,
        teamsId: this.currentTreeObj.teamsId,
        patientId: this.currentTreeObj.patientId,
        visitUuid: this.currentTreeObj.visitUuid,
        status: 1,
        prescriptionType: this.prescriptionType,
      };
      console.log(arr);
      // return;
      if (this.currentTreeObj.id && this.currentTreeObj.id != "21") {
        params.id = this.currentTreeObj.id;
      }
      console.log(params);
      // return;
      this.$httpAes({
        url: this.$httpAes.adornUrl(
          params.id
            ? "/mbglPrescriptionRecords/update"
            : "/mbglPrescriptionRecords/add"
        ),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status && data.data.id) {
          this.addDrugInfo(arr, data.data.id, this.prescriptionType);
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
    },
    // 删除西药填写的药品信息
    deleteDrugInfo2(row, index) {
      let arr = [];
      if (row.id) {
        row.status = 2;
        arr.push(row);

        this.$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonClass: "btn-custom-cancel",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl("/mbglPrescriptionDrugs/adds"),
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            data: arr,
          }).then(({ data }) => {
            if (data.status) {
              if (this.addRecipelList2.length == 2) {
                this.$httpAes({
                  url: this.$httpAes.adornUrl(
                    "/mbglPrescriptionRecords/deleteByIds"
                  ),
                  method: "post",
                  data: { stringParam1: row.prescriptionRecordsId},
                }).then(({ data }) => {
                  if (data.status) {
                    this.$message({
                      message: "删除成功",
                      type: "success",
                      duration: 1500,
                      onClose: () => {
                        this.getInfo(this.currentTreeObj);
                        this.addRecipelList2.splice(index, 1);
                      },
                    });
                  } else {
                    this.$message({
                      message: data.msg,
                      type: "error",
                      duration: 1500,
                      onClose: () => {},
                    });
                  }
                });
              } else {
                this.$message({
                  message: "删除成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.addRecipelList2.splice(index, 1);
                    // this.$refs["orgTree"].getDeptList();
                  },
                });
              }
            } else {
              this.$message({
                message: data.msg,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          });
        });
      } else {
        this.addRecipelList2.splice(index, 1);
      }
    },
    //点击选择协定方展示弹窗
    showGreetment() {
      this.agreementDialog = true;
      this.$nextTick(() => {
        this.$refs.agreement.init(this.currentTreeObj);
      });
    },
    showAddGreement(item) {
      this.addAgreementDialog = true;
      this.$nextTick(() => {
        this.$refs.addAgreement.init(item);
      });
    },
    showHistory() {
      this.historyDialog = true;
      this.$nextTick(() => {
        this.$refs.history.init(this.currentTreeObj);
      });
    },
    // 选择历史处方回调方法
    addRecipelItem(row) {
      let params = {};
      params.recipelForm = {
        frequency: row.frequency, //频次
        singleDoses: row.singleDoses, //单次剂数
        total: row.total, //总量
        medicationRequirements: row.medicationRequirements, //服药要求
        prescriptionType: 1, //中药
      };
      params.addRecipelList = [];
      if (
        row.mbglPrescriptionDrugsList &&
        row.mbglPrescriptionDrugsList.length
      ) {
        row.mbglPrescriptionDrugsList.forEach((ele) => {
          let obj = {
            drugInfo: {
              id: ele.drugsId,
              drugsFullName: ele.drugsName,
            },
            // id: ele.id,
            drugsDose: ele.drugsDose,
            drugsId: ele.drugsId,
            drugsName: ele.drugsName,
            drugsUseUnit: ele.drugsUseUnit,
            prescriptionRecordsId: ele.prescriptionRecordsId,
            medicationRequirements: ele.medicationRequirements,
          };
          params.addRecipelList.push(obj);
        });
      }
      params.addRecipelList.push({
        drugInfo: {},
        drugsDose: "",
        drugsUseUnit: "",
        medicationRequirements: "",
      });
      params.editStatus = true;
      this.realRecipelList.unshift(params);
      this.realRecipelList = JSON.parse(JSON.stringify(this.realRecipelList));
      this.isCreate = true;
      this.isAddRecipel = true;
      // this.getRecipelUnit("单位");
      // this.getRecipelUnit("用药频次");
      // this.getRecipelUnit("处方用法");
      // this.getRecipelUnit("天数");
      // this.getRecipelUnit("单剂次数");
      // this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.form-title {
  font-size: 20px;
  color: #0099ff;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px 0 40px;
}
.qsContent {
  // width: 1210px;
  width: 100%;
  // height: calc(100vh - 1.975rem);
  height: 560px;
  border: 1px solid #f2f2f2;
  position: relative;
  overflow: auto;
}
.btn {
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // margin-left: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tip-con {
  display: flex;
  height: 40px;
  align-items: center;
  background: #e6f7ff;
  border: 1px solid #bae7ff;
  color: #666;
  padding-left: 16px;

  &.tip-con2 {
    background: #fff0e6;
    border: 1px solid #ff9f62;
  }

  img {
    width: 16px;
    height: 14px;
    margin-right: 8px;
  }
}

.phone {
  // width: 375px;
  min-height: 100%;
  padding: 20px;
  margin: 24px auto;
  overflow: auto;
  margin: 0 auto;

  .btn {
    display: flex;
    justify-content: center;

    .el-button {
      margin-top: 0px;
      width: 20%;
      margin-bottom: 20px;
    }
  }
}

.theder {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-radio {
        height: 100%;
        width: 150px;
        opacity: 0;
        position: absolute;

        // z-index: 10;
        .el-radio__input {
          .el-radio__inner {
            border: 0px;
          }
        }

        .el-radio__input.is-checked {
          .el-radio__inner {
            background: none;
          }
        }
      }
    }
  }
}

.all-rule-list {
  .el-main {
    padding: 0 20px;
    position: relative;
  }

  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .share-box {
    line-height: 3em;
  }

  .copy-box {
    display: flex;
    justify-content: space-between;

    .qrcode {
      width: 200px;
      height: 200px;
    }
  }

  .el-button--text {
    white-space: normal;
  }
}

.tree-con {
  width: 360px;
  height: 500px;
  border: 1px solid #eeeeee;
  padding-top: 24px;
  margin-right: 24px;
  top: 300px;
}
.divider-con {
  width: 19px;
  height: 649px;
  background: #fafafa;
}

.user,
.sex {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}
.prescription {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px 24px;
  width: 100%;
  .drug-btn {
    background: #f2faff;
    border-radius: 2px;
    border: 1px solid #009cff;
    color: #0099ff;
  }
  .el-table thead tr th.el-table__cell {
    background: #f0f0f0 !important;
  }
  .prescription-name {
    font-size: 16px;
    font-weight: bold;
    padding: 24px 0 0;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .img-box {
    padding-top: 60px;
    img {
      width: 223px;
      height: 122px;
    }
  }
  .tip-text {
    color: #999999;
    padding-top: 20px;
    padding-bottom: 64px;
  }
  .drug-type-name {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 30px 0 28px;
    justify-content: center;
    position: relative;
    width: 100%;
    .btn-group2 {
      position: absolute;
      right: 0;
      display: flex;
    }
  }
  .add-con {
    // border: 1px solid #f2f2f2;
    width: 100%;
    .con-title2 {
      padding: 0 24px;
      height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
    }
    .con-title {
      height: 84px;
      background: #f5f5f5;
      border-bottom: 1px solid #d9d9d9;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close-btn {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .recipel-form {
      padding-top: 24px;
      .el-form-item {
        width: 100%;
        .el-form-item__content {
          width: 93.6%;
        }
      }
      .recipel-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
        .el-form-item {
          width: 100%;
          .el-form-item__content {
            width: 70%;
          }
        }
      }
    }
    .detail-con {
      padding: 25px 32px 10px;
      .delete-btn {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .west-drug-form {
    overflow: hidden;
    padding: 24px 32px;
    .el-form-item {
      float: left;
      width: 50%;
      margin-right: 0;
      .el-form-item__content {
        width: 50%;
      }
      .el-input {
        width: 100%;
      }
      .el-select {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
      &.custom-form-item {
        .el-form-item__content {
          width: 82%;
        }
      }
    }
  }
}
.save-btn {
  display: flex;
  justify-content: center;
  // padding-bottom: 10px;
}
.save-btn2 {
  position: fixed;
  right: 48px;
  bottom: 20%;
  img {
    width: 56px;
    height: 56px;
  }
}
.exist-list {
  .list-item {
    padding-top: 32px;
    border-bottom: 1px solid #e8e8e8;
    .top-con {
      display: flex;
      height: 54px;
      border-radius: 4px 4px 0 0;
      background: #fafafa;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;

      .drug-title {
        font-size: 16px;
        font-weight: bold;
      }
      .drug-desc {
        font-size: 14px;
      }

      .btns {
        display: flex;
        div {
          cursor: pointer;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 16px;
        }
        .save-btn {
          width: 22px;
          height: 19px;
          margin-right: 12px;
        }
      }
    }
    .el-table thead tr th.el-table__cell {
      background: #f0f0f0 !important;
    }
    .edit-status {
      // border: 1px solid #f2f2f2;
      .top-con {
        // background: none;
        // padding-left: 0;
        padding-right: 16px;
        margin-bottom: 16px;
        .btns {
          align-items: center;
          div {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .save-btn {
            margin-right: 0;
          }
          .save-text {
            color: #009cff;
            padding-right: 20px;
            padding-left: 2px;
          }
          .delete-text {
            color: #dd0606;
            padding-left: 2px;
          }
          img {
            margin-right: 0;
          }
        }
      }
      .delete-btn {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .drug-name {
      padding: 2px 25px 0 16px;
      color: #666;
      .name-list {
        overflow: hidden;
        width: 100%;
        .name-item {
          float: left;
          width: 20%;
          margin-right: 5%;
          height: 44px;
          &:last-child {
            margin-right: 0;
          }
          display: flex;
          // justify-content: space-between;
          padding-top: 10px;
          .name {
            width: 112px;
            padding: 0;
          }
          .unit {
            padding-left: 8px;
          }
        }
      }
      .demand {
        padding-top: 24px;
        padding-bottom: 13px;
      }
    }
  }
}
</style>
